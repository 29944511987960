// extracted by mini-css-extract-plugin
export var alignLeft = "s_qh d_bG";
export var alignCenter = "s_bP d_bD";
export var alignRight = "s_qj d_bH";
export var textAlignLeft = "s_q5";
export var textAlignCenter = "s_q6";
export var textAlignRight = "s_q7";
export var embedInnerWrapperDesign1 = "s_q8 d_bM";
export var embed = "s_q9 d_b1";
export var titleMargin = "s_rb d_cw d_dw";
export var subtitleMargin = "s_rc d_cs d_dw";
export var paragraphMargin = "s_rd d_cw d_dw";
export var SubtitleSmall = "s_qd J_qd J_tv J_tH";
export var SubtitleNormal = "s_qf J_qf J_tv J_tJ";
export var SubtitleLarge = "s_qg J_qg J_tv J_tK";
export var BodySmall = "s_rf J_rf J_tv J_tP";
export var BodyNormal = "s_rg J_rg J_tv J_tQ";
export var BodyLarge = "s_rh J_rh J_tv J_tR";