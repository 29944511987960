// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "x_rS d_gv d_cs";
export var heroHeaderCenter = "x_gw d_gw d_cs d_dw";
export var heroHeaderRight = "x_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "x_rT d_gr d_cw";
export var heroParagraphCenter = "x_gs d_gs d_cw d_dw";
export var heroParagraphRight = "x_gt d_gt d_cw d_dx";
export var heroSliderBtnWrapperLeft = "x_r6 d_d2 d_d1 d_w d_bz d_bG d_bJ";
export var heroSliderBtnWrapperCenter = "x_r7 d_d3 d_d1 d_w d_bz d_bD d_bJ";
export var heroSliderBtnWrapperRight = "x_r8 d_d4 d_d1 d_w d_bz d_bH d_bJ";
export var btnWrapper = "x_d2";
export var wrapper = "x_qm d_bz d_bP d_w d_H";
export var background = "x_ng d_bd d_0 d_8 d_7 d_4 d_9";
export var frameWrapper = "x_r9 d_w d_H d_Z d_4 d_7 d_bk d_bf d_bv";
export var videoFrame = "x_sb d_0";
export var videoOverlay = "x_sc d_w d_H d_0 d_4";
export var arrow = "x_kG d_0 d_n";
export var arrowLeft = "x_sd x_kG d_0 d_n";
export var arrowRight = "x_sf x_kG d_0 d_n";
export var buttonsWrapper = "x_sg d_bz d_bP";
export var dotsWrapper = "x_sh d_bz d_bP d_bD d_0";
export var dot = "x_sj d_bx d_dB";
export var dotActive = "x_sk x_sj d_bx d_dB";
export var heroSliderBackgroundImage = "x_gz d_w d_H d_bR d_bk";
export var loadingWrapper = "x_sl d_bd d_0 d_8 d_7 d_4 d_9";
export var topAlt = "x_gC d_gC d_gB d_bz d_bP d_w";
export var top = "x_gD d_gD d_gB d_bz d_bP d_w";
export var bottomAlt = "x_gG d_gG d_bd d_0 d_8 d_7 d_4 d_9 d_gB d_bz d_bP";
export var bottom = "x_gH d_gH d_bd d_0 d_8 d_7 d_4 d_9 d_gB d_bz d_bP";
export var heroExceptionSmall = "x_r0 J_r0";
export var heroExceptionNormal = "x_r1 J_r1";
export var heroExceptionLarge = "x_r2 J_r2";