// extracted by mini-css-extract-plugin
export var carouselContainer = "T_w5 d_w d_H d_bf d_Z";
export var carouselContainerCards = "T_w6 T_w5 d_w d_H d_bf d_Z";
export var carouselContainerSides = "T_w7 d_w d_H d_Z";
export var prevCarouselItem = "T_w8 d_w d_H d_0 d_k";
export var prevCarouselItemL = "T_w9 T_w8 d_w d_H d_0 d_k";
export var moveInFromLeft = "T_xb";
export var prevCarouselItemR = "T_xc T_w8 d_w d_H d_0 d_k";
export var moveInFromRight = "T_xd";
export var selectedCarouselItem = "T_xf d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "T_xg T_xf d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "T_xh T_xf d_w d_H d_Z d_bf";
export var nextCarouselItem = "T_xj d_w d_H d_0 d_k";
export var nextCarouselItemL = "T_xk T_xj d_w d_H d_0 d_k";
export var nextCarouselItemR = "T_xl T_xj d_w d_H d_0 d_k";
export var arrowContainer = "T_xm d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "T_xn T_xm d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "T_xp T_xn T_xm d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "T_xq T_xm d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "T_xr";
export var nextArrowContainerHidden = "T_xs T_xq T_xm d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "T_kG d_0";
export var prevArrow = "T_xt T_kG d_0";
export var nextArrow = "T_xv T_kG d_0";
export var carouselIndicatorContainer = "T_xw d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "T_xx d_w d_bz d_bF";
export var carouselText = "T_xy d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "T_xz T_xy d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "T_xB d_b7";
export var carouselIndicator = "T_xC T_xB d_b7";
export var carouselIndicatorSelected = "T_xD T_xB d_b7";
export var arrowsContainerTopRight = "T_xF d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "T_xG d_0 d_bl d_bC";
export var arrowsContainerSides = "T_xH d_0 d_bl d_bC";
export var smallArrowsBase = "T_xJ d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "T_xK T_xJ d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "T_xL T_xK T_xJ d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "T_xM T_xJ d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "T_xN T_xM T_xJ d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "T_p9";
export var iconSmall = "T_xP";
export var multipleWrapper = "T_xQ d_bC d_bF d_bf";
export var multipleImage = "T_xR d_bC";
export var sidesPrevContainer = "T_xS T_xK T_xJ d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "T_xT T_xK T_xJ d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";