// extracted by mini-css-extract-plugin
export var noPadding = "N_fj d_fj";
export var videoFrameWrapper = "N_vV d_kS d_b5";
export var videoIframeStyle = "N_pS d_d5 d_w d_H d_by d_b1 d_R";
export var videoLayoutRight = "N_vW d_kR d_bK";
export var videoInnerWrapper = "N_vX d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5";
export var videoText = "N_vY d_dw d_w";
export var videoInnerInnerWrapper = "N_vZ d_bz d_bD d_bJ";
export var btnWrapper = "N_d2 d_w d_bz d_bD d_cj";
export var btn = "N_mB d_b1 d_bz d_bD d_bP d_cH d_b6 d_dB d_n J_t1 J_tZ";
export var icon = "N_p9 d_cC";
export var textLeft = "N_dv";
export var textCenter = "N_dw";
export var textRight = "N_dx";