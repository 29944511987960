// extracted by mini-css-extract-plugin
export var alignLeft = "B_qh d_fp d_bG d_dv";
export var alignCenter = "B_bP d_fq d_bD d_dw";
export var alignRight = "B_qj d_fr d_bH d_dx";
export var productsContainer = "B_sM d_dW";
export var productsTextWrapper = "B_sN d_0 d_9 d_w d_cG";
export var productsImageElement = "B_lF";
export var productsModalWrapper = "B_sP d_bz d_bL";
export var productsModalWrapperDesign3 = "B_l1 d_l1 d_bz d_bM d_bT d_bD d_cG d_bf";
export var modalLeftColumn = "B_sQ d_r d_H";
export var modalLeftColumnDesign2 = "B_sR d_r d_H";
export var modalCloseIcon = "B_sS d_dB";
export var modalRightColumn = "B_sT d_r d_H d_bT d_bz d_bM d_cG";
export var productsComponentsWrapperLeft = "B_lY d_lY d_y d_w d_cv d_dB";
export var modalCloseButton = "B_sV d_w d_bz d_bL d_bP d_bH";
export var modalCloseButtonDesign3 = "B_sW d_w d_bz d_bL d_bP d_bH";
export var modalTextWrapperDesign3 = "B_sX d_H d_bz d_bM d_bP";
export var modalTextWrapper = "B_sY d_bz d_bM d_bP";
export var modalCarouselWrapper = "B_sZ d_H d_bz d_bL d_bD";
export var carouselWrapper = "B_k6 d_H d_bz d_bL d_bD";
export var productsCarouselImageWrapper = "B_s0 d_lS d_H d_w";
export var productsCarouselImageWrapperSides = "B_s1 d_H";
export var productsCarouselImageWrapperDesign3 = "B_lT d_lT d_bf";
export var productsCarouselWrapper = "B_lV d_lV d_bz d_bD d_bP d_w d_H";
export var productsCarouselWrapperDesign3 = "B_lW d_lW d_bz d_bD d_bP d_w";
export var productsCarouselImg = "B_mX d_lX d_w d_H d_bR";
export var productsImageModalWrapper = "B_lH";
export var productsImageElementDesign3 = "B_lJ d_lJ d_hH d_w d_H d_bR";
export var productsContainerFull = "B_s2 d_dT";
export var productsMainHeader = "B_l3 d_l3 d_l2 d_w d_ct";
export var productsMainSubtitle = "B_l4 d_l4 d_l2 d_w d_cy";
export var productsComponentHeading = "B_l5 d_l5";
export var productsComponentParagraph = "B_l6 d_l6";
export var productsCard = "B_lK d_lK d_bd d_0 d_8 d_7 d_4 d_9 d_by d_y d_W d_H";
export var productsCardWrapper = "B_lL d_lL d_by d_w d_Z";
export var productsCardWrapperDesign2 = "B_lM d_lM d_w d_H d_bz d_bM d_bT";
export var productsTextBlockDesign2Wrapper = "B_s3 d_cG";
export var productsTextBlockDesign3Wrapper = "B_lN d_lN d_w d_bz d_bM d_bD d_bP d_cG d_fn d_0";
export var productsTextBlockDesign2 = "B_lP d_lP d_bz d_bM d_H";
export var productsTextBlockDesign3 = "B_lQ d_lQ d_bz d_bM d_bP d_bl";
export var productsComponentsWrapperDesign2 = "B_lZ d_lZ d_y d_w d_bz d_bM d_cv d_bT";
export var productsComponentsWrapperDesign3 = "B_l0 d_l0 d_y d_w d_bz d_bM d_cv d_dB";
export var colEqualHeights = "B_s4 d_bz";
export var productsImageWrapperLeft = "B_lB d_lB d_w d_Z d_bx";
export var productsImageWrapperDesign2 = "B_lC d_lC d_w";
export var design2TextWrapper = "B_s5 d_r d_bC d_bP d_bJ";
export var exceptionWeight = "B_qL J_tX";