// extracted by mini-css-extract-plugin
export var campaignContainer = "n_qk d_dW";
export var campaignContainerFull = "n_ql d_dT";
export var wrapper = "n_qm d_w";
export var leftWrapper = "n_qn d_bz d_bN";
export var rightWrapper = "n_qp";
export var subtitle = "n_qq";
export var title = "n_qr";
export var text = "n_qc d_cs";
export var inlineWrapper = "n_qs d_bC d_bG d_w";
export var btnsWrapper = "n_qt n_qs d_bC d_bG d_w d_bJ";
export var priceWrapper = "n_qv n_qs d_bC d_bG d_w d_ch d_bP";
export var priceHeading = "n_qw";
export var disclaimerWrapper = "n_qx d_cD";
export var mediaSlot = "n_qy";
export var listWrapper = "n_qz d_bC d_bP d_w";
export var btnWrapper = "n_d2";
export var imageWrapper = "n_qB d_fg d_Z";
export var SmallSmall = "n_qC J_qC J_tv J_tS";
export var SmallNormal = "n_qD J_qD J_tv J_tT";
export var SmallLarge = "n_qF J_qF J_tv J_tQ";
export var textLeft = "n_dv";
export var textCenter = "n_dw";
export var textRight = "n_dx";
export var alignLeft = "n_qh";
export var alignCenter = "n_bP";
export var alignRight = "n_qj";