// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "M_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "M_fR d_fR d_bz d_bJ";
export var menuDesign6 = "M_vm d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "M_vn d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "M_vp d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "M_vq d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "M_vr d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "M_m9 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "M_nl d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "M_vs";
export var navbarItem = "M_nb d_bx";
export var navbarLogoItemWrapper = "M_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "M_vt d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "M_vv d_gd d_by d_Z d_bs";
export var burgerToggle = "M_vw d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "M_vx d_gd d_by d_Z d_bs";
export var burgerInput = "M_vy d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "M_vz d_f3 d_w d_H";
export var burgerLine = "M_vB d_f1";
export var burgerMenuDesign6 = "M_vC d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "M_vD d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "M_vF d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "M_vG d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "M_vH d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "M_vJ d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "M_vK d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "M_vL d_bC d_bP";
export var compact = "M_vM";
export var navDivided = "M_vN";
export var staticBurger = "M_vP";
export var menu = "M_vQ";
export var navbarDividedLogo = "M_vR";
export var nav = "M_vS";
export var fixed = "M_vT";