// extracted by mini-css-extract-plugin
export var alignDiscLeft = "t_rj d_fp d_bG d_dv";
export var alignLeft = "t_qh d_fp d_bG d_dv";
export var alignDiscCenter = "t_rk d_fq d_bD d_dw";
export var alignCenter = "t_bP d_fq d_bD d_dw";
export var alignDiscRight = "t_rl d_fr d_bH d_dx";
export var alignRight = "t_qj d_fr d_bH d_dx";
export var footerContainer = "t_rm d_dW d_bW";
export var footerContainerFull = "t_rn d_dT d_bW";
export var footerHeader = "t_kf d_kf";
export var footerTextWrapper = "t_rp d_w";
export var footerDisclaimerWrapper = "t_km d_km d_ck";
export var badgeWrapper = "t_rq d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "t_rr d_bz d_bJ d_bN d_bL";
export var wide = "t_rs d_cy";
export var right = "t_rt d_bK";
export var line = "t_fk d_fl d_cv";
export var badgeDisclaimer = "t_rv d_bC d_bP d_bJ";
export var badgeContainer = "t_rw d_bz d_bH d_bP";
export var badge = "t_rx";
export var padding = "t_ry d_c7";
export var end = "t_rz d_bH";
export var linkWrapper = "t_rB d_dB";
export var link = "t_mC d_dB";
export var colWrapper = "t_rC d_cx";
export var consent = "t_f d_f d_bC d_bP";
export var disclaimer = "t_rD d_bz d_bJ";
export var media = "t_rF d_bx d_dy";
export var itemRight = "t_rG";
export var itemLeft = "t_rH";
export var itemCenter = "t_rJ";
export var exceptionWeight = "t_qL J_tX";