// extracted by mini-css-extract-plugin
export var alignLeft = "z_qh d_fp d_bG d_dv";
export var alignCenter = "z_bP d_fq d_bD d_dw";
export var alignRight = "z_qj d_fr d_bH d_dx";
export var alignColumnLeft = "z_sr d_fs d_bN d_dv";
export var alignColumnCenter = "z_ss d_ft d_bP d_dw";
export var alignColumnRight = "z_st d_fv d_bQ d_dx";
export var milestonesContainer = "z_sv d_dW";
export var milestonesContainerFull = "z_sw d_dT";
export var milestonesSubtitle = "z_mf d_mf d_cs";
export var milestonesComponentWrapper = "z_mb d_mb d_cv";
export var compContentWrapper = "z_sx d_mc d_bz d_bJ d_bM d_bF d_c7 d_H";
export var milestonesMainHeader = "z_l8 d_l8 d_w d_cr";
export var milestonesSubHeader = "z_l9 d_l9 d_w";
export var milestonesComponentWrapperRow = "z_sy d_H";
export var milestonesBlockWrapper = "z_sz";
export var milestonesTextBlockWrapper = "z_sB";
export var milestonesComponentHeader = "z_md d_md d_w d_cr";
export var milestonesComponentParagraph = "z_mj d_mj d_w";
export var btnWrapper = "z_d2 d_bz d_ck";
export var btnWrapperCards = "z_sC d_bz d_cd";
export var imageWrapper = "z_qB d_cs d_bz";
export var Title1Small = "z_r3 J_r3 J_tv J_tw";
export var Title1Normal = "z_r4 J_r4 J_tv J_tx";
export var Title1Large = "z_r5 J_r5 J_tv J_ty";
export var Title2Small = "z_sD J_sD J_tv J_tz";
export var Title2Normal = "z_sF J_sF J_tv J_tB";
export var Title2Large = "z_sG J_sG J_tv J_tC";
export var SubtitleSmall = "z_qd J_qd J_tv J_tH";
export var SubtitleNormal = "z_qf J_qf J_tv J_tJ";
export var SubtitleLarge = "z_qg J_qg J_tv J_tK";
export var Subtitle2Small = "z_sH J_sH J_tv J_tL";
export var Subtitle2Normal = "z_sJ J_sJ J_tv J_tM";
export var Subtitle2Large = "z_sK J_sK J_tv J_tN";
export var BodySmall = "z_rf J_rf J_tv J_tP";
export var BodyNormal = "z_rg J_rg J_tv J_tQ";
export var BodyLarge = "z_rh J_rh J_tv J_tR";
export var exceptionLineHeight = "z_sL J_tY";