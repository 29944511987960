// extracted by mini-css-extract-plugin
export var lbContainerOuter = "R_wQ";
export var lbContainerInner = "R_wR";
export var movingForwards = "R_wS";
export var movingForwardsOther = "R_wT";
export var movingBackwards = "R_wV";
export var movingBackwardsOther = "R_wW";
export var lbImage = "R_wX";
export var lbOtherImage = "R_wY";
export var prevButton = "R_wZ";
export var nextButton = "R_w0";
export var closing = "R_w1";
export var appear = "R_w2";