// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "C_s6 d_gS d_cw d_dv";
export var quoteParagraphCenter = "C_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "C_gV d_gV d_cw d_dx";
export var quoteRowLeft = "C_s7 d_bG";
export var quoteRowCenter = "C_s8 d_bD";
export var quoteRowRight = "C_s9 d_bH";
export var quoteWrapper = "C_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "C_gR d_gR";
export var quoteExceptionSmall = "C_tb J_tb";
export var quoteExceptionNormal = "C_tc J_tc";
export var quoteExceptionLarge = "C_td J_td";
export var quoteAuthorExceptionSmall = "C_tf J_tf";
export var quoteAuthorExceptionNormal = "C_tg J_tg";
export var quoteAuthorExceptionLarge = "C_th J_th";