// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "w_rS d_gv d_cs";
export var heroHeaderCenter = "w_gw d_gw d_cs d_dw";
export var heroHeaderRight = "w_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "w_rT d_gr d_cw";
export var heroParagraphCenter = "w_gs d_gs d_cw d_dw";
export var heroParagraphRight = "w_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "w_rV d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "w_rW d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "w_rX d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "w_rY d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "w_rZ d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "w_r0 J_r0";
export var heroExceptionNormal = "w_r1 J_r1";
export var heroExceptionLarge = "w_r2 J_r2";
export var Title1Small = "w_r3 J_r3 J_tv J_tw";
export var Title1Normal = "w_r4 J_r4 J_tv J_tx";
export var Title1Large = "w_r5 J_r5 J_tv J_ty";
export var BodySmall = "w_rf J_rf J_tv J_tP";
export var BodyNormal = "w_rg J_rg J_tv J_tQ";
export var BodyLarge = "w_rh J_rh J_tv J_tR";